import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  FileField,
  FileInput,
  FunctionField,
  List,
  SelectInput,
  ShowButton,
  SimpleForm,
  useNotify,
  useRedirect,
  usePermissions,
  required,
  Link,
  useDataProvider,
  TopToolbar,
  FilterButton,
  CreateButton
} from "react-admin";
import LinearProgress from "@mui/material/LinearProgress";
import { AccessTime, Check, Close, RunCircle } from "@mui/icons-material";
import AutoscoreJobFiltersAdmin from "./autoscoreJobFiltersAdmin";
import { Typography } from "@mui/material";

const supportedSports = [
  { id: "AL", name: "Alpine" },
  { id: "MA", name: "Alpine/Masters" }
];

// element 0 is the default value and should be set to current season
// multiple seasons can be selected by the competition-admin group
// users drop-down is disabled, so the only choice is element 0
const runSeasons = [
  { id: "2025", name: "2025" },
  { id: "2024", name: "2024" },
  { id: "2023", name: "2023" }
];

const ListActionsAdmin = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton label="upload race results" />
  </TopToolbar>
);

const ListActions = () => (
  <TopToolbar>
    <CreateButton label="upload race results" />
  </TopToolbar>
);

export const AutoscoreJobList = () => {
  const ldapId = localStorage.getItem("ldap-id");
  const auth = usePermissions();

  if (auth.permissions && auth.permissions.includes("competition-services")) {
    return (
      <>
        <List
          title="Results List"
          sort={{ field: "start", order: "DESC" }}
          filter={{ jobId: "autoscore-job" }}
          filters={AutoscoreJobFiltersAdmin}
          exporter={false}
          debounce={1000}
          empty={false}
          sx={{
            "& .RaList-main": { width: "100%" },
            "& .RaList-content": { overflow: "auto" }
          }}
          actions={<ListActionsAdmin />}
        >
          <Datagrid bulkActionButtons={false} rowClick="show">
            <FunctionField
              label="Season"
              render={(record: any) => record.parameters.run_season}
            />
            <FunctionField
              label="Sport"
              render={(record: any) =>
                supportedSports.find((sport) => {
                  return sport.id === record.parameters.sport_code;
                })?.name ?? "Unknown"
              }
            />
            ,
            <FunctionField
              label="File Submitted"
              render={(record: any) => record.parameters.file_name}
            />
            ,
            <FunctionField
              label="Rescore?"
              render={(record: any) =>
                record.parameters.rescore ? "Yes" : "No"
              }
            />
            ,
            <FunctionField
              label="Generated By"
              render={(record: any) =>
                record.consumerId
                  ? `${record.consumerId.split(",")[0].split("=")[1]}`
                  : null
              }
            />
            ,
            <FunctionField
              label="Progress"
              render={(record: any) => (
                <LinearProgress variant="determinate" value={record.progress} />
              )}
            />
            ,
            <DateField source="start" />,
            <FunctionField
              label="Status"
              render={(record: any) => {
                switch (record.status) {
                  case "done":
                    return <Check color="success" titleAccess="Complete" />;
                  case "error":
                    return <Close color="error" titleAccess="Error" />;
                  case "running":
                    return <RunCircle color="info" titleAccess="Running Now" />;
                  default:
                    return (
                      <AccessTime
                        color="info"
                        titleAccess="Queued to Run Shortly"
                      />
                    );
                }
              }}
            />
            ,
            <FunctionField
              label="NPS"
              render={(record: any) => {
                if (record.result) {
                  return record.result.npsWarning === true ? (
                    <Close color="error" titleAccess="Error" />
                  ) : (
                    <Check color="success" titleAccess="Complete" />
                  );
                } else {
                  return "N/A";
                }
              }}
            />
            ,
            <FunctionField
              label="Packet File"
              render={(record: any) => {
                return record.parameters.packet_file_attached ? (
                  <Check color="success" titleAccess="Complete" />
                ) : (
                  <Close color="error" titleAccess="Error" />
                );
              }}
            />
            ,
            <ShowButton />
          </Datagrid>
        </List>
        <div style={{ display: "flex" }}>
          <Link
            style={{ paddingTop: "27px" }}
            target="_blank"
            to="https://forms.monday.com/forms/0c996bc0de46d5e82bac43afbcd7febb?r=use1"
          >
            <Typography
              style={{
                float: "right",
                fontVariantCaps: "all-small-caps",
                fontWeight: 200,
                marginBottom: "2px"
              }}
            >
              Open a new feature request or bug report
            </Typography>
          </Link>
        </div>
        <div>
          <Typography
            style={{
              float: "right",
              fontSize: "12px",
              fontWeight: 200,
              marginBottom: "2px"
            }}
          >
            v{import.meta.env.VITE_APP_VERSION}
          </Typography>
        </div>
      </>
    );
  } /* RA view */ else {
    return (
      <List
        sort={{ field: "start", order: "DESC" }}
        filter={{ consumerId: ldapId, consumerType: "user" }}
        exporter={false}
        debounce={1000}
        actions={<ListActions />}
        empty={false}
        sx={{
          "& .RaList-main": { width: "100%" },
          "& .RaList-content": { overflow: "auto" }
        }}
      >
        <Datagrid bulkActionButtons={false} rowClick="show">
          <FunctionField
            label="Season"
            render={(record: any) => record.parameters.run_season}
          />
          ,
          <FunctionField
            label="Sport"
            render={(record: any) =>
              supportedSports.find((sport) => {
                return sport.id === record.parameters.sport_code;
              })?.name ?? "Unknown"
            }
          />
          ,
          <FunctionField
            label="File Submitted"
            render={(record: any) => record.parameters.file_name}
          />
          ,
          <FunctionField
            label="Progress"
            render={(record: any) => (
              <LinearProgress variant="determinate" value={record.progress} />
            )}
          />
          ,
          <DateField source="start" />,
          <FunctionField
            label="Status"
            render={(record: any) => {
              switch (record.status) {
                case "done":
                  return <Check sx={{ color: "green" }} />;
                case "error":
                  return <Close sx={{ color: "red" }} />;
                default:
                  return <RunCircle sx={{ color: "blue" }} />;
              }
            }}
          />
          ,
          <ShowButton />
        </Datagrid>
      </List>
    );
  }
};

const validateJobCreate = (values) => {
  const errors = {};
  if (!values.sport_code) {
    errors["sport_code"] = "Choose the sport for scoring.";
  }
  if (!values.results_file_selected) {
    errors["results_file_selected"] =
      "Choose a single results XML or ZIP file to score.";
  }
  if (values.results_file_selected?.rawFile?.size === 0) {
    errors["results_file_selected"] =
      "File selected is empty.  Choose another to score.";
  }
  return errors;
};

export const AutoscoreJobCreate = (props: Object) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const auth = usePermissions();
  const dp = useDataProvider();
  const ldapId = localStorage.getItem("ldap-id");

  const onSuccess = (data) => {
    // job is in; save associated XML and PDF files
    const resultsFileParams = {
      data: {
        file_selected: data.results_file_selected,
        metadata: {
          runSeason: data.run_season,
          jobId: data.id,
          target: "autoscore-job",
          extendedType: "results"
        },
        tags: ["autoscore-job"]
      }
    };
    // create results file through files api
    dp.create("files", resultsFileParams)
      .then((response) => {
        if (!data.packet_file_selected) {
          // just notify and redirect
          notify("results file uploaded successfully");
          redirect(`/autoscore-job/${data.id}/show`);
        } else {
          // create job packet PDF file
          const packetFileParams = {
            data: {
              file_selected: data.packet_file_selected,
              metadata: {
                runSeason: data.run_season,
                jobId: data.id,
                target: "autoscore-job",
                extendedType: "packet"
              },
              tags: ["autoscore-job"]
            }
          };
          // create race packet file through files api
          dp.create("files", packetFileParams)
            .then((response) => {
              notify("results and packet files uploaded successfully");
              redirect(`/autoscore-job/${data.id}/show`);
            })
            .catch((err) => {
              // couldn't create packet file
              notify(
                "packet file was not uploaded successfully; race results will still be processed."
              );
              redirect(
                "show",
                "autoscore-job",
                data.id,
                {},
                { fileSaveError: true }
              );
            });
        }
      })
      .catch((err) => {
        // couldn't create results file
        notify(
          "results file was not uploaded successfully; race results will still be processed."
        );
        redirect("show", "autoscore-job", data.id, {}, { fileSaveError: true });
      });
  };

  return (
    <Create
      {...props}
      mutationOptions={{ onSuccess }}
      redirect="show"
      title="Process Results"
    >
      <div>
        <Typography
          style={{ float: "left", marginRight: "5px" }}
          sx={{ fontStyle: "italic" }}
          variant="caption"
        >
          *** NOTE:
        </Typography>
      </div>
      <div style={{ display: "inline-block", width: "70%" }}>
        <Typography
          style={{ float: "left" }}
          sx={{ fontStyle: "italic" }}
          variant="caption"
        >
          Currently only Alpine and Masters are able to be submitted using this
          form.
        </Typography>
        <Typography
          style={{ float: "left" }}
          sx={{ fontStyle: "italic" }}
          variant="caption"
        >
          All other sports should continue to use their existing results scoring
          process.
        </Typography>
      </div>
      <SimpleForm validate={validateJobCreate}>
        <div style={{ display: "flex" }}>
          <SelectInput
            source="sport_code"
            label="Sport"
            choices={supportedSports}
            defaultValue={supportedSports[0].id}
            validate={required()}
            sx={{ margin: "0px 5px 0px 5px" }}
          />
          {auth.permissions &&
          auth.permissions.includes("competition-services") ? (
            <SelectInput
              source="run_season"
              label="Season"
              choices={runSeasons}
              defaultValue={runSeasons[0].name}
              validate={required()}
              sx={{ margin: "0px 5px 0px 5px" }}
            />
          ) : (
            <SelectInput
              source="run_season"
              label="Current Season"
              choices={runSeasons}
              defaultValue={runSeasons[0].name}
              validate={required()}
              disabled={true}
              sx={{ margin: "0px 5px 0px 5px" }}
            />
          )}
          {auth.permissions &&
          auth.permissions.includes("competition-services") ? (
            <BooleanInput
              sx={{ marginLeft: "20px" }}
              label="Allow rescore?"
              source="rescore"
            />
          ) : null}
        </div>
        <div
          style={{
            float: "left",
            borderWidth: "1px",
            borderStyle: "solid",
            padding: "5px 10px 5px 10px",
            width: "60%"
          }}
        >
          <FileInput
            sx={{ float: "left" }}
            helperText="XML or ZIP files only; ZIPs should contain only one race result XML.  Drag/drop file here or click to select it from the file browser."
            placeholder=">>> Drop file here, or click to select it. <<<"
            source="results_file_selected"
            label="RESULTS XML FILE"
            accept=".xml,.zip"
          >
            <FileField source="src" title="title" target="_blank" />
          </FileInput>
        </div>
        <div>
          <Typography
            style={{ float: "left" }}
            sx={{ color: "red" }}
            variant="caption"
            width="50%"
            marginTop="10px"
          >
            Race packet upload is optional. If the race packet is ready to
            upload at the same time as results, use the upload file box below.
            <br />
            <br />
            If the race packet is <em>not</em> ready at the same time, you may
            send it later to resultpackets@usskiandsnowboard.org. PLEASE include
            the race codex with the packet.
          </Typography>
        </div>
        <div
          style={{
            float: "right",
            borderWidth: "1px",
            borderStyle: "solid",
            padding: "5px 10px 5px 10px",
            width: "60%"
          }}
        >
          <FileInput
            sx={{ float: "left" }}
            helperText="PDF files with single race packet; ZIPs may contain multiple files.  Drag/drop file here or click to select it from the file browser."
            placeholder=">>> Drop file here, or click to select it. <<<"
            source="packet_file_selected"
            label="RACE PACKET PDF FILE"
            accept=".pdf,.zip"
          >
            <FileField source="src" title="title" target="_blank" />
          </FileInput>
        </div>
      </SimpleForm>
      <Typography style={{ float: "right", fontSize: "12px", fontWeight: 200 }}>
        v{import.meta.env.VITE_APP_VERSION}
      </Typography>
    </Create>
  );
};
